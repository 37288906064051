<template>
  <router-link :class="classname" :title="$t('Basket.BasketButton')" to="/cart">
    <span v-if="itemCount.length < 100" class="alarm">
      {{ itemCount.length }}
    </span>
  </router-link>
</template>

<script>
import { reactive, toRefs } from 'vue-demi';

export default {
  props: ['setCount'],
  setup() {
    const basket = localStorage.getItem('basket');
    const items = basket ? JSON.parse(basket) : [];
    const state = reactive({
      itemCount: items,
    });
    const classname = ['menu', 'cart'];

    if (items.length >= 100) {
      classname.push('small');
    }

    if (items.length > 0) {
      classname.push('on');
    }

    return {
      ...toRefs(state),
      items,
      handleSetCount() {
        const basket = localStorage.getItem('basket');
        const items = basket ? JSON.parse(basket) : [];
        state.itemCount = items;
      },
      classname: classname.join(' '),
    };
  },
};
</script>

<style>
.alarm.small {
  font-size: 12px;
}
</style>
