<template>
  <div class="item_box wrap">
    <header class="layout_header">
      <div class="hinner slid_up">
        <nav class="gnb">
          <div class="left_header">
            <a
              type="button"
              :class="getMenuClassName('w')"
              @click="handleClickTab('w')"
              >{{ $t('Item.WOMEN') }}
            </a>
            <a
              type="button"
              :class="getMenuClassName('m')"
              @click="handleClickTab('m')"
            >
              {{ $t('Item.MEN') }}
            </a>
          </div>
          <search-cart-icons />
        </nav>
      </div>
    </header>

    <div :class="`${isLoading ? '' : 'off'}layout_body`">
      <!-- 필터 선택한 후 -->
      <filter-slider
        v-if="filterSlideItems.length > 0"
        :items="filterSlideItems"
        @click="handleClickTag"
      />

      <div
        class="inner ptb14"
        :style="filterSlideItems.length > 0 ? 'margin-top:54px;' : ''"
      >
        <article class="women_product item">
          <div class="prod_swiper noswiper_prod p0">
            <ul>
              <li v-for="(item, idx) in items" :key="item.cit_id">
                <product-item :key="idx" :item="item" :bookMark="true" />
              </li>
              <infinite-loading
                v-if="hasMore"
                :identifier="infiniteId"
                style="opacity: 0"
                @infinite="loadDatas"
              />
            </ul>
          </div>
        </article>
      </div>
    </div>
    <div v-if="!isLoading" class="loading_wrap">
      <div class="loading_content">
        <img src="../../assets/images/design/loading.gif" alt="loading" />
      </div>
    </div>
    <div>
      <div v-if="isLoading" class="disflex btn_box filter-box">
        <button
          type="button"
          class="order_filter btn_lineblack btn_pop_open"
          @click="isShowSort = true"
        >
          {{ $t('Brand.OrderBy') }}
        </button>
        <button
          type="button"
          class="filter_filter btn_lineblack btn_pop_open"
          @click="handleClickFilter"
        >
          {{ $t('Item.Filter') }}
        </button>
      </div>

      <sild-footer class="items-sild-footer" />
    </div>
    <items-filter-popup
      v-if="isShowFilterPopup"
      v-model="filters"
      :tabOptions="tabOptions"
      @close="isShowFilterPopup = false"
      @input="handleInputFilter"
    />
    <sort-popup
      v-if="isShowSort"
      :title="$t('Brand.OrderBy')"
      :items="sortItems"
      @close="isShowSort = false"
      @select="handleSelectedSort"
    />
  </div>
</template>

<script>
import { reactive, ref, toRefs, onMounted, onUpdated } from 'vue-demi';
import ProductAPI from '../../apis/ProductAPI';
import SearchAPI from '../../apis/SearchAPI';
import InfiniteLoading from 'vue-infinite-loading';
import ProductItem from '../../components/ui/ProductItem/ProductItem.vue';
import SildFooter from '../../components/layouts/components/footers/SildFooter.vue';
import { addClassNavDown, toggleNav } from '../../utils/GlobalUtils';
import ItemsFilterPopup from './components/ItemsFilterPopup.vue';
import filterItems from '@/consts/FilterItems';
import SearchCartIcons from '../../components/layouts/components/headers/components/RightHeader/SearchCartIcons.vue';
import SortPopup from '../../components/popups/SortPopup.vue';
import FilterSlider from '../../components/ui/FilterSlider/FilterSlider.vue';
import useUserInfo from '../../composables/user/useUserInfo';

export default {
  components: {
    InfiniteLoading,
    ProductItem,
    SildFooter,
    ItemsFilterPopup,
    SearchCartIcons,
    SortPopup,
    FilterSlider,
  },
  setup(_, context) {
    onMounted(() => {
      console.log('ItemsView onMounted');
    });
    onUpdated(() => {
      console.log('ItemsView onUpdated');
    });
    console.log('ItemsView');
    const { info } = useUserInfo();
    let isScroll = ref(false);
    let maxPrice = 0;

    const state = reactive({
      type: 'w',
      hasMore: true,
      infiniteId: Date.now(),
      items: [],
      isShowFilterPopup: false,
      filters: {},
      filterSlideItems: [],
      isShowSort: false,
      tabOptions: 'ItemsView',
      isLoading: false,
    });

    const params = {
      page: 0,
      limit: 10,
      mem_id: info?.mem_id ?? 0,
      orderBy: localStorage.getItem('item_sort_key'),
    };

    let beforePosition = document.documentElement.scrollTop;
    let isScrolling;
    window.addEventListener('scroll', function () {
      window.clearTimeout(isScrolling);
      isScrolling = setTimeout(function () {
        isScroll.value = false;
      }, 66);
    });
    window.addEventListener('scroll', function () {
      let afterPosition = document.documentElement.scrollTop;
      if (afterPosition > 50) {
        if (beforePosition < afterPosition) {
          // 스크롤 위로
          isScroll.value = true;
        } else {
          // 스크롤 아래로
          isScroll.value = false;
        }
      }
      beforePosition = afterPosition;
    });

    const createSearchParamValue = (key, value) => {
      switch (key) {
        case 'category1':
        case 'category2':
        case 'category3':
          return Object.entries(value)
            .filter(value => {
              console.log(value);
              if (value[1]) return value;
            })
            .map(value => {
              return value[0];
            });

        case 'concept':
          return value.filter(concept => concept);

        case 'age':
          return value.filter(age => age);

        case 'color':
          return value.filter(color => color);

        case 'style':
          return value.filter(style => style);

        case 'price':
        case 'soldOut':
          return value;

        default:
          return '';
      }
    };

    const createSearchParams = () => {
      const filters = {
        ...params,
        type: state.type,
      };

      console.log('createSearchParams before');
      console.log(state.filters);
      Object.keys(state.filters).forEach(key => {
        const value = state.filters[key];
        filters[key] = createSearchParamValue(key, value);
      });
      console.log('createSearchParams after');
      console.log(filters);
      return filters;
    };

    const loadDatas = async attr => {
      console.log('loadDatas');
      const search = createSearchParams();
      try {
        const { data } = await SearchAPI.searchItem(search);
        state.items = [...state.items, ...data.body.result.result_data];
        maxPrice = data.body.result.max_price;
        if (data.body.result.result_data.length !== params.limit) {
          attr?.complete();
          state.hasMore = false;
        } else {
          params.page++;
          attr?.loaded();
          console.log('loaddata success');
        }
        state.isLoading = true;
      } catch (error) {
        console.log(error);
        const { data } = await ProductAPI.getItemsByType(search);
        state.items = [...state.items, ...data.result_data];
        if (data.result_data.length !== params.limit) {
          attr?.complete();
          state.hasMore = false;
        } else {
          params.page++;
          attr?.loaded();
        }
        state.isLoading = true;
      }
    };

    let filterValues = null;

    (async () => {
      filterValues = await filterItems.getItemFilterOptions();
    })();

    const clearSearchParams = () => {
      params.page = 0;
      state.items = [];
      state.hasMore = true;
      state.infiniteId = Date.now();
    };

    const getFilterTags = filters => {
      const filterItems = [];
      Object.keys(filters).forEach(key => {
        const value = filters[key];
        switch (key) {
          case 'category1':
          case 'category2':
          case 'category3':
            console.log('category123');
            console.log(filters);
            console.log(filterValues);
            for (const [idAndLabel, checked] of Object.entries(value)) {
              if (checked) {
                console.log('checked filterValues[category][idx]');
                console.log(idAndLabel);
                const label = idAndLabel.split('#')[1];
                filterItems.push({
                  path: `${key}$$${idAndLabel}`,
                  label: label,
                });
              }
            }
            break;
          case 'concept':
          case 'age':
          case 'color':
            value.forEach((checked, idx) => {
              if (checked) {
                filterItems.push({
                  path: `${key}$$${idx}`,
                  label: filterValues[key][idx].label,
                });
              }
            });
            break;

          case 'price':
            console.log('price');
            value &&
              Number(maxPrice) !== Number(value[1]) &&
              filterItems.push({
                path: key,
                label: `${value[0]}~${value[1]}`,
              });
            break;

          case 'soldOut':
            value &&
              filterItems.push({
                path: key,
                label: context.root.$t('Item.SoldOutExcept'),
              });
            break;

          default:
            return '';
        }
      });
      console.log('filterItems');
      console.log(filterItems);
      return filterItems;
    };
    const sortItems = [
      {
        key: '',
        label: context.root.$t('Brand.OrderByRecommand'),
      },
      {
        key: 'new_dtm',
        label: context.root.$t('Brand.OrderByNew'),
      },
      {
        key: 'upd_dtm',
        label: context.root.$t('Brand.OrderByUpdate'),
      },
      {
        key: 'cit_sale_price_desc',
        label: context.root.$t('Brand.OrderByHigh'),
      },
      {
        key: 'cit_sale_price_asc',
        label: context.root.$t('Brand.OrderByLow'),
      },
      {
        key: 'sale',
        label: context.root.$t('Brand.OrderByDiscount'),
      },
    ];

    return {
      ...toRefs(state),
      loadDatas,
      isScroll,
      sortItems,
      getMenuClassName(value) {
        const classes = ['menu', 'fontEn'];

        if (value === state.type) {
          classes.push('on');
        }

        return classes.join(' ');
      },
      handleClickFilter() {
        const node = document.querySelector('header .slid_up');

        addClassNavDown(node);

        state.isShowFilterPopup = true;
      },
      handleInputFilter(filters) {
        console.log('handleInputFilter');
        console.log(filters);
        state.filterSlideItems = getFilterTags(filters);
        state.isShowFilterPopup = false;
        clearSearchParams();
      },
      handleClickTag(item) {
        console.log('handleClickTag');
        console.log(item);
        const paths = item.path.split('$$');
        console.log(state.filters);
        const filters = { ...state.filters };
        console.log(paths);
        console.log(filters);

        if (paths.length > 1) {
          filters[paths[0]][paths[1]] = false;
        }

        if (paths[0] === 'price') {
          filters['price'] = undefined;
        }

        if (paths[0] === 'soldOut') {
          filters['soldOut'] = false;
        }

        state.filters = filters;

        state.filterSlideItems = getFilterTags(state.filters);

        clearSearchParams();
      },
      handleSelectedSort(item) {
        state.isShowSort = false;
        params.orderBy = item.key;
        localStorage.setItem('item_sort_key', item.key);

        clearSearchParams();
      },
      handleClickTab(type) {
        state.type = type;
        clearSearchParams();
      },
    };
  },
  created() {
    window.addEventListener('scroll', toggleNav);
  },
  destroyed() {
    window.removeEventListener('scroll', toggleNav);
  },
};
</script>

<style>
.btn_box.filter-box {
  bottom: 74px;
}
.btn_box.filter-box.nav-down {
  bottom: 74px;
}
.btn_box.filter-box.nav-up {
  bottom: -74px;
}
.filter-box.slid_up {
  top: auto !important;
  transition: bottom 0.2s ease-in-out !important;
}
.main .layout_body {
  padding-top: 0;
}
.main .hinner {
  background-color: transparent;
  border-bottom: 0;
}
.main .hinner.bg {
  border-bottom: 1px solid #202020;
}
.loading_content {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 90px);
  background: transparent;
  z-index: 999;
}
.loading_content img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  width: 40px;
  height: 40px;
  border-radius: 50em;
}
@media (min-width: 1240px) {
  .loading_content {
    max-width: 360px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.layout_body.off {
  opacity: 0;
}
</style>
